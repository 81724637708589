/* déplacer les regles css en rapport direct avec le header ici */
.profiImg {
  /* padding-top: 10vh; */
  height: 30vh;
  min-height: 100px;
  float: none !important;
  cursor: pointer;
}

.nameH1 {
  text-align: center !important;
  font-size: 8rem;
}

.bigName {
  margin-top: 200px;
  font-size: 100px;
  font-weight: 800;
  font-weight: bold; /* Poids de la police pour les initiales */
}

.normalName {
  font-weight: normal; /* Poids de la police pour le reste du texte */
}

.noStyle {
  text-decoration: none; /* Enlève le soulignement par défaut */
  color: inherit; /* Assure que la couleur du texte est héritée */
  display: inline; /* Permet au texte de rester en ligne */
  transition: none; /* Désactive les transitions qui pourraient affecter le style */
}

.noStyle:hover {
  font-weight: inherit; /* Conserve le poids de la police au survol */
  text-decoration: none; /* Enlève le soulignement au survol */
}

@media (max-width: 1215px) {
  .profiImg {
    height: 25vh;
  }

  .nameH1 {
    font-size: 6rem;
  }

  .bigName {
    font-size: 80px;
  }
}

@media (max-width: 910px) {
  .profiImg {
    height: 10vh;
  }

  .nameH1 {
    font-size: 5rem;
  }

  .bigName {
    font-size: 60px;
  }
}

@media (max-width: 629px) {
  .profiImg {
    height: 10vh;
    margin-top: 50px;
  }
}
