/* PAGE PRINCIPAGE DE LA COLLECT/TOUTES PAGES */

.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  text-align: center;
}

.main-h2 {
  margin-top: 20px;
}

.collection-psw-form {
  margin-top: 20px;
}

.collection-psw-form input {
  display: block;
  margin: auto;
  margin-bottom: 20px;
}

.menu-button {
  display: block;
  margin: auto;
  margin-bottom: 10px;
}

/* COMPOSANT D'OPTIONS DE FILTRAGE */

.collection-options-container {
  margin: auto;
  border: 1px solid #fffff8; /* Bordure de 1px avec une couleur gris clair */
  border-radius: 8px; /* Angles arrondis */
  padding: 10px; /* Espace intérieur */
  margin-bottom: 15px; /* Espacement en bas de la div */
  max-width: 50%; /* Largeur maximale pour la div */
  text-align: left; /* Aligne le contenu à gauche */
}

.collection-options-container h3 {
  font-size: medium;
  font-weight: 600;
  width: 100%;
  margin: 0px;
  display: inline;
}

.collection-options-container label {
  margin: 0px;
}
.collection-options-container select {
  margin-bottom: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.checkbox-group {
  display: flex;
  gap: 10px;
  align-items: center;
}

/* Conteneur pour les propriétaires */
.owner-filters {
  display: flex;
  flex-wrap: wrap; /* Permet de retourner à la ligne quand plus de place */
  gap: 0px 10px; /* Espacement entre les éléments */
  margin-bottom: 10px; /* Espacement entre les sections */
}

/* Conteneur pour les plateformes */
.platform-filters {
  display: flex;
  flex-wrap: wrap; /* Permet de retourner à la ligne quand plus de place */
  gap: 0px 10px; /* Espacement entre les éléments */
}

.platform-filters-top {
  width: 100%;
}

.platform-filters h3 {
  display: inline-block;
  width: fit-content;
}

.platform-buttons {
  display: inline-block;
}

.platform-buttons button {
  padding: 0px;
  font-size: 12px; /* Taille de la police plus petite */
  line-height: 1.5; /* Réduit la hauteur de la ligne */
  height: auto; /* Assure que la hauteur s'ajuste automatiquement */
  margin-left: 10px;
}

.owner-filters label {
  margin: 0px;
  font-weight: 100;
  margin-top: 5px;
}

.platform-filters label {
  margin: 0px;
  font-weight: 100;
  margin-top: 5px;
}

.order-by,
.search-bar {
  display: inline-block;
}

.search-bar {
  float: right;
}

/* GALERIE DES JEUX DE LA COLLECTION */

.collection-games-grid {
  width: 50%;
  margin: auto;
  padding: 10px;
  border: 1px dashed #4c4c4c;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 colonnes */
  gap: 20px; /* Espacement entre les éléments */
}

.collection-games-grid h2 {
  font-size: medium;
}

.collection-games-grid h3 {
  font-size: small;
}

.game-title {
  width: fit-content;
  margin: auto;
  margin-bottom: 10px; /* Espace entre le titre et l'image */
  text-align: center;
  cursor: pointer;
}

.game-cover {
  width: 100px;
  height: auto;
  cursor: pointer;
}

.game-item {
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  overflow: visible; /* Assure que .platform-info est visible au survol */
}

/* Overlay semi-transparent */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #4c4c4c98; /* Assombrir le fond */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Mettre le modal devant le reste */
}

/* Contenu du modal */
.modal-content {
  background: #fffff8;
  padding: 20px;
  border: 1px solid #4c4c4c;
  max-width: 50%;
  width: 90%;
  max-height: 80vh; /* Limite la hauteur à 80% de la hauteur de la fenêtre */
  overflow-y: auto; /* Ajoute un scroll vertical si le contenu dépasse la hauteur maximale */
  z-index: 1001;
  position: relative;
}

.modal-content h2 {
  font-size: xx-large;
  font-weight: bold;
}

.modal-content h3 {
  font-size: medium;
}

.modal-cover {
  max-width: 50%;
  margin: auto;
}

/* Conteneur général pour la couverture et les détails */
.media-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 20px;
}

/* Couverture du jeu */
.media-cover {
  max-width: 40%;
  height: auto;
  margin: auto;
}

/* Conteneur des détails du jeu (carrousel + résumé) */
.media-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 55%;
  margin: auto;
}

/* Carrousel de screenshots */
.media-carousel {
  margin-bottom: 20px;
}

.carousel-slide {
  display: flex;
  overflow: hidden;
}

.carousel-slide img {
  max-width: 100%;
  height: auto;
}

/* Résumé du jeu */
.media-summary {
  font-size: 16px;
  color: #333;
  line-height: 1.5;
  padding-top: 30px;
  text-align: left;
}

/* Flèches de navigation du carrousel */
.carousel-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.carousel-button {
  background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent */
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1; /* Assurer que les boutons sont au-dessus du carrousel */
}

.carousel-button:hover {
  background-color: rgba(
    0,
    0,
    0,
    0.8
  ); /* Couleur de fond plus sombre au survol */
}

.screenshots-container {
  margin-bottom: 30px;
}

.platform-info {
  display: flex;
  gap: 1rem; /* Espacement entre les plateformes */
  align-content: center;
  width: 500px;
  margin: auto;
  margin-bottom: 20px;
}

.platform-info span {
  margin: auto;
}

.platform-info .platform-logo {
  width: 48px; /* Ajuste la taille du logo selon tes besoins */
  height: auto;
}

.platform-info .tooltip {
  display: none;
  position: absolute;
  background: #fffff8;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  white-space: nowrap; /* Pour éviter les retours à la ligne */
  z-index: 10; /* Assurer que le tooltip est au-dessus des autres éléments */
}

.platform-info .platform-logo:hover + .tooltip {
  display: block;
}

/* INUTILE MAINTENANT ? */

.platform-logo {
  width: 48px;
  height: auto;
  margin-right: 5px;
  border-radius: 25%;
  border: 2px solid #4c4c4c;
  padding: 2px;
}

.platform-only-psplus {
  border: 2px dashed #4c4c4c;
}

.owners-infos {
  font-size: small;
  margin-bottom: 0px;
}

.free-info {
  font-size: small;
  margin-bottom: 0px;
}

.comments {
  font-size: small;
}

/* Bouton de fermeture */
.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0px;
}

.modal-loading,
.modal-error {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #4c4c4c;
  color: #fffff8;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  z-index: 1001; /* Assure que le message est au-dessus du modal */
  width: 50%;
}

.modal-loading {
  background-color: #4c4c4c;
  color: #fffff8;
}

.modal-error {
  background-color: #4c4c4c;
  color: red;
}

.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.page-button {
  background-color: #fffff8;
  color: #4c4c4c98;
  border: 2px solid #4c4c4c98; /* Bordure plus épaisse */
  padding: 0 10px;
  margin: 0; /* Supprimer l'espace entre les boutons */
  cursor: pointer;
  border-radius: 0; /* Réinitialiser les bordures arrondies */
  transition: background-color 0.3s ease;
  font-size: large;
  height: 30px; /* Hauteur fixe pour les boutons */
  display: flex; /* Pour centrer le texte verticalement */
  align-items: center;
  justify-content: center;
}

.page-button:hover {
  background-color: #fffff8;
}

.page-button.active {
  background-color: #4c4c4c98;
  color: #fff;
  cursor: default;
}

.page-button.ellipsis {
  cursor: default;
  background-color: transparent;
  border: none;
  color: #4c4c4c98;
}

/* Bordure arrondie uniquement à gauche pour le premier bouton */
.page-button.first {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* Active les bordures arrondies des coins droits sur page 1 de pagination si seulement 1 page */
.pagination-container .page-button:first-child:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

/* Bordure arrondie uniquement à droite pour le dernier bouton */
.page-button.last {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.reload-button-container {
  width: 50%;
  margin: auto;
  height: fit-content;
  display: inline-flex;
}

.reload-button {
  font-size: 12px;
  line-height: 2;
  height: auto;
  margin-left: auto; /* Pousse le bouton vers la droite */
}

/* PAGE D'AJOUT */

.add-game-container {
  padding-top: 20px;
}

.add-game-form {
  margin-bottom: 0px;
}

.add-game-form label,
.modify-game-form label {
  width: fit-content;
  margin: auto;
}

.text-input-name {
  display: block;
  margin-bottom: 10px;
}

/* PAGE DE MODIF */

.possession-box {
  width: 50%;
  margin: auto;
  margin-bottom: 20px;
  padding-top: 20px;
  border: 1px dashed #4c4c4c;
}

.possession-box label {
  width: 100%;
}

.possession-box select {
  max-width: 100%;
}

@media (max-width: 1500px) {
  .owner-filters label {
    font-size: 13px;
  }
  .platform-filters label {
    font-size: 13px;
  }
  .media-summary {
    font-size: 13px;
  }

  .reload-button-container {
    display: inline;
  }

  .reload-button {
    margin-top: 50px;
  }
}

/* Media Query pour écran de 1000px et moins */
@media (max-width: 1000px) {
  .collection-options-container h3 {
    font-size: smaller;
  }
  .collection-options-container label {
    font-size: 10px;
  }
  .collection-options-container label {
    font-size: 10px;
  }
  .collection-options-container input {
    margin-bottom: 0px;
  }
  .collection-options-container input {
    margin-bottom: 0px;
  }

  .platform-buttons button {
    font-size: 10px;
  }

  .collection-games-grid {
    width: 75%; /* Utiliser 75% de la largeur de l'écran */
    grid-template-columns: repeat(2, 1fr); /* Afficher 2 jeux par ligne */
  }

  .media-container {
    flex-direction: column; /* Aligner les éléments en colonne */
    gap: 0; /* Enlever l'espacement horizontal */
  }

  .media-cover {
    max-width: 75%; /* Utiliser toute la largeur disponible */
    margin-bottom: 20px; /* Espacement en dessous de la couverture */
  }

  .media-carousel {
    max-width: 75%;
    padding: 12.5%;
  }

  .media-details {
    max-width: 100%; /* Les détails utilisent également toute la largeur */
  }

  .slick-dots {
    bottom: -40px;
  }

  .slick-dots li {
    width: 5px;
    height: 10px;
  }

  .platform-info {
    flex-direction: column; /* Aligner les plateformes en colonne sur petit écran */
    align-items: center;
    width: 100%; /* Utiliser toute la largeur */
  }

  .platform-info h4 {
    text-align: center;
    margin-bottom: 10px;
  }

  .platform-info .platform-logo {
    width: 50px; /* Encore plus petit pour les très petits écrans */
  }
}

@media (max-width: 800px) {
  .order-by select,
  .search-bar input {
    height: fit-content;
    padding: 0px;
  }
  .search-bar {
    float: none;
    margin-bottom: 10px;
  }
}

/* Media Query pour écran de 600px et moins (mobile) */
@media (max-width: 600px) {
  .centered-container {
    display: block;
    height: auto;
  }

  .platform-info .tooltip {
    font-size: 12px; /* Ajuster la taille du texte dans les tooltips */
    max-width: 200px; /* Limiter la largeur du tooltip */
  }

  .modal-content {
    width: 80vw;
  }

  .platform-buttons button {
    margin-left: 0px;
    display: inline-block;
  }

  .close-button {
    top: 10px;
    right: 5px;
    font-size: smaller;
  }
}

@media (max-width: 450px) {
  .collection-games-grid {
    width: 90%; /* Utiliser 90% de la largeur de l'écran */
    grid-template-columns: 1fr; /* Afficher 1 jeu par ligne */
  }

  .modal-content {
    position: absolute;
    width: 70vw;
    max-width: none;
    min-width: none;
    left: 10vw;
  }

  .page-button {
    padding: 0 5px;
    font-size: smaller;
  }
}

@media (max-height: 900px) {
  .centered-container {
    display: block;
    height: auto;
  }
}
