#prod {
  text-align: center;
  /* min-height: 100vh; */
}

.prod-row {
  display: flex;
  margin: 10px;
}

.prod-category {
  display: grid;
  align-content: center;
  border: 1px dashed #4c4c4c;
}

.categorie-h2 {
  font-size: xx-large;
  font-weight: 700;
  margin-top: 2rem;
}

.prod-content {
  padding: 2% 1%;
  border: 1px dashed #4c4c4c;
  /* height: 90vh; */
}

.prod-content img {
  width: 90%;
}

.prod-content iframe {
  border: 3px solid #4c4c4c;
}

.prod-content canvas {
  border: 3px solid #4c4c4c;
  width: 100%;
  display: block;
}

.hidden-prod {
  margin-top: 0px;
  display: none;
}

#empty-img {
  height: 1px;
  display: none;
}

.skills {
  width: 30px !important;
  border: none !important;
  display: inline-block;
  vertical-align: middle;
  margin-left: 2px;
  margin-right: 2px;
}

.processingSkill,
.gamemakerSkill {
  margin-top: 0.5vh;
}

.skills-list {
  color: #4c4c4c;
  cursor: pointer;
}

.modalSkillsDiv {
  margin-top: 20px;
  margin-bottom: 5px;
}

.web-skills-modal-content {
  background-color: #fffff8;
  margin: auto;
  padding: 20px;
  border: 1px solid #4c4c4c;
  width: 50%;
}

.web-skills-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.close-web-skills-modal {
  position: relative;
  top: -22px;
  right: -6px;
  color: #4c4c4c;
  float: right;
  font-size: 28px;
  font-weight: bolder;
}

.close-web-skills-modal:hover,
.close-web-skills-modal:focus {
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}

.ss-modal-content-v {
  background-color: #fffff8;
  margin: auto;
  border: 1px solid #4c4c4c;
  width: 70% !important;
  cursor: default !important;
}

.ss-modal-content-h {
  background-color: #fffff8;
  margin: auto;
  border: 1px solid #4c4c4c;
  width: 40% !important;
  cursor: default !important;
}

.ss-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 10vh; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/*
.close-ss-modal-h {
  position: absolute;
  width: 50px;
  height: 50px;
  color: #fffff8;
  font-size: 28px;
  font-weight: bolder;
  z-index: 1;
  right: 29vw;
  top: 9vh;
}

.close-ss-modal-v {
  position: absolute;
  width: 50px;
  height: 50px;
  color: #fffff8;
  font-size: 28px;
  font-weight: bolder;
  z-index: 1;
  right: 14vw;
  top: 9vh;
}
*/

.close-ss-modal:hover,
.close-ss-modal:focus {
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}

.skills-precisions {
  font-size: x-small;
}

.protogame-skills-modal-content {
  background-color: #fffff8;
  margin: auto;
  padding: 20px;
  border: 1px solid #4c4c4c;
  width: 50%;
}

.protogame-skills-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.close-protogame-skills-modal {
  position: relative;
  top: -22px;
  right: -6px;
  color: #4c4c4c;
  float: right;
  font-size: 28px;
  font-weight: bolder;
}

.close-protogame-skills-modal:hover,
.close-protogame-skills-modal:focus {
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}

.web-screenshots {
  margin-top: 2vh;
  margin-bottom: 2vh;
}

#prod .column {
  margin: 0;
}

.prevnext {
  margin-top: 2vh;
  float: none;
  min-width: 20px;
  max-width: 60px;
  border: none;
  font-size: 250%;
  text-align: center;
  align-content: center;
  padding: inherit;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 0px;
}

#play-button {
  margin-top: 20px;
  margin-bottom: 25px;
  background-color: #fffff8;
  color: #4c4c4c;
}

#play-button:hover {
  background-color: #4c4c4c;
  color: #fffff8;
}

#cant-play {
  color: red;
  display: none;
  font-weight: 700;
  margin-top: 15px;
}

#cant-play-temp {
  color: red;
  font-weight: 700;
  margin-top: 15px;
}

#proto-screenshots {
  margin: auto;
  width: 50%;
  margin-bottom: 25px;
}

#proto-screenshots img:hover {
  cursor: zoom-in;
}

/*
#proto {
  margin: 0;
  padding: 0;
  height: 38px;
}
*/

canvas {
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

#unityContainer {
  /* PEUT ETRE INUTILE SI FAIT PAR JQUERY */
  width: 70vw;
  /* PEUT ETRE INUTILE SI FAIT PAR JQUERY */
  height: 45vw;
  margin: auto !important;
  background-color: #fffff8 !important;
}

#construct-g {
  position: relative;
  width: 80%;
  height: 75vh;
  margin: auto;
}

#construct-g iframe {
  border: none;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
}

#bubble {
  position: relative;
  width: 100%;
}

#bubble iframe {
  top: 0%;
  left: 0%;
  width: 500px;
  height: 495px; /* 500px cause some weird white space at the bottom of the iframe */
}

#colorgame {
  position: relative;
  width: 100%;
}

#colorgame iframe {
  top: 0%;
  left: 0%;
  width: 700px;
  height: 500px;
}

#anothercolorgame {
  position: relative;
  width: 100%;
}

#anothercolorgame iframe {
  top: 0%;
  left: 0%;
  width: 800px;
  height: 500px;
}

#ibpg {
  height: auto !important;
}

#ibpg iframe {
  width: 100%;
  height: 1000px;
  position: relative;
  right: 2px;
}

#construct-mmo-g {
  position: relative;
  width: 1600px;
  height: 900px;
  margin: auto;
}

#construct-mmo-g iframe {
  width: 100%;
  height: 100%;
}

#cube iframe {
  width: 960px;
  height: 960px; /* 500px cause some weird white space at the bottom of the iframe */
}

.progress-bar {
  display: inline;
  font-size: x-large;
}

@media (max-width: 1215px) {
  .categorie-h2 {
    font-size: x-large;
  }
}
