body {
  background-color: #fffff8;
  font-family: 'Bahnschrift', helvetica, arial, sans-serif;
  color: #4c4c4c;
  font-weight: 100;
}

html {
  scroll-behavior: smooth;
}

* {
  scrollbar-width: thin;
}

::selection {
  color: #fffff8;
  background-color: #4c4c4c;
}

#main-div {
  margin: 1%;
  text-align: center;
}

.important {
  font-weight: 700;
}

.italic-name {
  font-style: italic;
}

a,
.internal-link,
.skills-list {
  text-decoration: underline;
  color: #4c4c4c;
  cursor: pointer;
}

a:hover,
.internal-link:hover,
.skills-list:hover {
  font-weight: 500;
  text-decoration: underline;
  color: #4c4c4c;
  cursor: pointer;
}

footer {
  margin-top: 20px;
  text-align: center;
  bottom: 0;
  width: 100%;
  font-size: small;
}

footer a {
  color: #4c4c4c;
  text-decoration: none;
}

footer a:hover {
  color: #4c4c4c;
  text-decoration: none;
  font-weight: 600;
}

.cta-button {
  background-color: #4c4c4c;
  color: #fffff8 !important;
  font-weight: bolder;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.cta-button:hover {
  transform: scale(1.05);
  background-color: #fffff8 !important;
  color: #4c4c4c !important;
}

.contact-form {
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: space-between; /* Sépare les sections à gauche et à droite */
  flex-wrap: wrap; /* Pour assurer que les champs se replacent sur petit écran */
}

.left-section {
  display: flex;
  flex-direction: column; /* Met les champs un en dessous de l'autre */
  width: 45%; /* Occupe 45% de la largeur totale */
}

.right-section {
  width: 45%; /* Occupe 50% de la largeur totale */
}

.contact-form div {
  margin-bottom: 15px;
}

.contact-form textarea {
  width: 100%;
  height: 100px; /* Ajuste la hauteur du champ de texte */
}

.contact-form button {
  margin-top: 20px;
  padding: 10px 20px;
  color: #fffff8;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.contact-error-message {
  color: red;
  font-weight: 700;
}

@media (max-width: 1000px) {
  .contact-form {
    width: 80%;
  }
}

/* Media query pour les écrans de 450px et moins */
@media (max-width: 450px) {
  .contact-form {
    flex-direction: column; /* Aligne tous les champs en colonne */
    align-items: stretch; /* Assure que les champs prennent toute la largeur */
  }

  .left-section,
  .right-section {
    width: 100%; /* Chaque section prend 100% de la largeur sur petit écran */
  }

  textarea {
    height: 120px; /* Ajuste la hauteur du champ de texte pour les petits écrans */
  }
}
